<template>
  <div class="text-sm font-normal" id="app">
    <main-sidebar></main-sidebar>
    <main-header></main-header>
    <div
      style="
        margin: 0 0 0 100px;
        max-height: calc(100vh - 60px);
        overflow-y: auto;
        padding: 30px;
      "
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import MainSidebar from "@/components/MainSidebar";
import MainHeader from "@/components/MainHeader";
import { whoami } from "@/api/auth";

export default {
  name: "app",
  components: {
    MainSidebar,
    MainHeader,
  },
  async created() {
    const user = await whoami();
    this.$store.commit("setUser", user.me);
  },
  mounted() {
    // let recaptchaScript = document.createElement("script");
    // recaptchaScript.setAttribute("id", "jivo");
    // recaptchaScript.setAttribute(
    //   "src",
    //   "https://code.jivosite.com/widget/zzOTJjNrMH"
    // );
    // document.head.appendChild(recaptchaScript);
    //code.jivosite.com/widget/zzOTJjNrMH
  },
};
</script>

<style lang="scss">
.el-loading-spinner .circular svg {
  display: inline;
}

.has-error {
  .el-input {
    .el-input__inner {
      border-color: red;
    }
  }
}

.el-table .warning-row {
  background: oldlace;
}
</style>
