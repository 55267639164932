<template>
  <header
    style="margin-left: 100px; height: 60px"
    class="border-b flex items-center px-5"
  >
    <div class="ml-auto">
      <el-dropdown @command="handleCommand" trigger="click">
        <div class="el-dropdown-link">
          {{ user.email }}<i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logout">Выход</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();

export default {
  name: "MainHeader",
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    handleCommand(command) {
      console.log(command);
      if (command === "logout") {
        localStorageService.clearToken();
        this.$router.push({ name: "auth" });
      }
    },
  },
};
</script>

<style scoped></style>
