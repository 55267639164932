<template>
  <div
    style="width: 100px; background-color: #2d3747"
    class="fixed left-0 top-0 h-full bg-red-100"
  >
    <header
      style="background-color: #19202c; height: 60px"
      class="h-16 flex items-center justify-center"
    >
      <router-link
        to="/"
        class="flex items-center justify-center text-white font-semibold text-xl"
      >
        <img class="w-8" src="@/static/images/logo.png" alt="" />
      </router-link>
    </header>
    <nav style="height: calc(100vh - 60px); overflow-y: auto" id="sidebar">
      <ul>
        <li v-for="menu in sidebarMenuItems" :key="menu.title">
          <router-link
            class="py-3 block text-white flex flex-col items-center text-xs text-center"
            :to="menu.route"
          >
            <i class="font-bold text-2xl mb-1" :class="menu.icon"></i>
            {{ menu.title }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MainSidebar",
  data: () => ({
    sidebarMenuItems: [
      {
        title: "Продукты",
        icon: "el-icon-s-goods",
        route: { name: "products" },
      },
      {
        title: "Точки продаж",
        icon: "el-icon-location",
        route: { name: "branches" },
      },
      {
        title: "CRM",
        icon: "el-icon-s-order",
        route: { name: "sales" },
      },
      // {
      //   title: "Аналитика(магазин)",
      //   route: { name: "analytics" },
      // },
      // {
      //   title: "Аналитика(kaspi-pay)",
      //   icon: "el-icon-s-tools",
      //   route: { name: "kaspi-pay" },
      // },
      {
        title: "Настройки",
        icon: "el-icon-s-tools",
        route: { name: "settings" },
      },
    ],
  }),
};
</script>

<style lang="scss">
#sidebar {
  ul {
    li {
      .router-link-active {
        background-color: rgba(255, 255, 255, 0.1);
        color: #5da5f3;
        border-color: #5da5f3;
      }
    }
  }
}
</style>
